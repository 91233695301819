<template>
  <div>Your logout !</div>
</template>

<script>
export default {
  beforeCreate () {
    localStorage.clear()
    this.$store.dispatch('auth/deleteBearer')
    this.$store.dispatch('removeActiveUser')
    this.$vs.notify({
      title: this.$t('login.notify.logout.title'),
      text: this.$t('login.notify.logout.text'),
      iconPack: 'feather',
      icon: 'icon-alert-circle',
      color: 'warning'
    })
    this.$router.push({'name': 'page-login'}).catch(() => {})
  }
}
</script>

<style scoped>

</style>
